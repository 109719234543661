<template>
  <div class="progressStatistic">
    <h2>{{ message }}</h2>
    <pie-chart :data="data" :library="library"></pie-chart>
  </div>
</template>

<script>
export default {
  props: ["data", "message"],
  data() {
    return {
      library: {
        is3D: true,
        pieSliceText: "value",
        legend: {
          position: "labeled",
          labeledValueText: "both",
          textStyle: {
            fontSize: 14,
          },
        },
        colors: ["#e62b26", "#50a2da", "#009e42", "#ec6667", "#f6c7b6", "#5e5e5e", "#9b9b9b"],
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.progressStatistic {
  padding-top: 10px;
  margin-top: 30px;
  background: white;
  h2 {
    text-transform: initial;
    font-size: 24px;
    color: black;
    font-family: $first_font;
    margin-left: 40px;
  }
}
</style>
