<template>
  <div class="goals__container">
    <AdminSection v-if="profile.role === 'superadmin' || profile.isSpecialFunctions" />
    <MonitorSection v-if="profile.role === 'monitor'" />
  </div>
</template>
<script>
  import AdminSection from "./AdminSection.vue";
  import MonitorSection from "./MonitorSection.vue";

  export default {
    components: { AdminSection, MonitorSection },
    computed: {
      profile() {
        return this.$store.getters["user/getProfile"];
      },
    },
  };
</script>
<style lang="scss">
  .goals__container {
    width: 100%;
    max-width: 820px;
  }
</style>
