<template>
  <div class="goalsAdmin">
    <h1 class="goalsAdmin__title">Lista de modelos</h1>
    <ModelGoalsPanel v-for="model in asignedModels" :key="model._id" :model="model" />
  </div>
</template>
<script>
import ModelGoalsPanel from "./ModelGoalsPanel.vue";

export default {
  components: { ModelGoalsPanel },
  computed: {
    asignedModels() {
      return this.$store.getters["users/getData"];
    },
  },
};
</script>
<style lang="scss">
.goalsAdmin {
  &__title {
    font-size: 32px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 25px;
    margin-top: 40px;
  }

  &__goalItems {
    margin: 0 20px;
    padding-bottom: 20px;
  }

  &__goalsList {
    margin-top: 20px;
  }

  .modelsGoalsCard {
    max-width: 800px;

    &-modelInfoContainer {
      display: flex;
      flex-direction: column;

      button {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 5px;
        height: 37px;
        border-radius: 6px;
        &:focus {
          outline: none;
        }
      }
      @media (min-width: $tablet_width) {
        padding-right: 20px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        button {
          margin: 0;
        }
      }
    }
    @media (min-width: $tv_width) {
      &-generalInfo {
        padding-top: 15px;
      }
      &-modelInfoContainer {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
</style>
