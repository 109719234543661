<template>
  <div class="goals">
    <fieldset class="goals__fieldset">
      <legend>Metas</legend>
      <div class="goalsNav__editGoal" v-for="goal in goals" :key="goal._id">
        <button v-if="goal.message" :class="['goalsNav__editBtn', !goal.enable ? 'disabled' : '']" @click="editVisible(goal._id)">
          <p v-html="goal.message"></p>
          <iconic name="edit_pencil" />
        </button>
        <InputEdit :isGoalEdit="true" :goal="goal" @closeEditTime="editVisible(goal._id)" />
      </div>
    </fieldset>
    <div class="goalsNav">
      <div class="goalsNav__search" v-if="sectionActive === 'models'">
        <SearchModels placeholder="Buscar modelo" class="notifications__Search" ref="search" @viewModel="showSelectedModel" />
      </div>
      <div class="goalsNav__selectContainer" v-if="sectionActive === 'models'">
        <select class="goalsNav__select" @change="filterModelsByCity" autocomplete="none">
          <option value="all" selected>Todas las ciudades</option>
          <option v-for="(value, key, i) in cities" :key="i" :value="value">{{ key }}</option>
        </select>
      </div>
      <div class="goalsNav__selectContainer" v-if="sectionActive === 'statistics'">
        <select class="goalsNav__select" @click="getStatistic" autocomplete="none">
          <option value="all">Todas las ciudades</option>
          <option value="general" selected>General</option>
        </select>
      </div>
      <div class="goalsNav__removeFilters" @click="cleanFilters" v-if="sectionActive === 'models'">
        <i class="fas fa-times"></i>
        <span>Quitar filtros</span>
      </div>
      <div class="goalsNav__selecSection">
        <div :class="['goalsNav__statistics', sectionActive === 'models' ? 'sectionActive' : '']" @click="sectionActive = 'models'">
          <iconic name="user" />
          <p>Modelos</p>
        </div>
        <div :class="['goalsNav__statistics', sectionActive === 'statistics' ? 'sectionActive' : '']" disabled>
          <iconic name="statistics" />
          <p>Estadísticas</p>
        </div>
      </div>
    </div>
    <section class="modelGoals" v-if="sectionActive === 'models' && modelsGoals.length">
      <div class="goals__cards">
        <ModelGoalsCard v-for="model in modelsGoals" :key="model._id" :model="model" />
        <div class="goals__pagination" fcenter v-if="hasMore && !isLoading && modelsGoals.length > 1">
          <button class="click-on-bottom generalBtn" @click="getMoreGoals">Ver mas</button>
        </div>
      </div>
      <div class="spinner" v-if="isLoading"></div>
    </section>
    <h1 v-else-if="!modelsGoals">Sin registros</h1>
    <div class="spinner" v-else></div>
    <section v-if="sectionActive === 'statistics'">
      <ProgressStatistic :data="statisticData" :message="statisticMessage" />
    </section>
  </div>
</template>
<script>
  import InputEdit from "./InputEdit.vue";
  import SearchModels from "./SearchModels.vue";
  import ModelGoalsCard from "./ModelGoalsCard.vue";
  import ProgressStatistic from "./ProgressStatistic.vue";
  import dictionary from "../../js/dictionary";

  export default {
    components: {
      SearchModels,
      ModelGoalsCard,
      InputEdit,
      ProgressStatistic,
    },
    data() {
      return {
        cities: {},
        city: null,
        sectionActive: "models",
        average: "",
        statisticData: {},
        statisticMessage: "",
        editIsVisible: true,
        page: 0,
        isLoading: false,
        isFiltering: false,
      };
    },
    methods: {
      async showSelectedModel(model) {
        this.$store.dispatch("goals/getModelGoalsById", model._id);
      },
      async filterModelsByCity({ target }) {
        this.page = 0;
        if (target.value === "all") return this.$store.dispatch("goals/getModelGoals", { reset: true });
        this.city = target.value;
        await this.$store.dispatch("goals/getModelGoals", { reset: true, city: target.value });
      },
      getStatistic(el) {
        this.calcStatistics(el.target.value);
      },
      async getAverage(models) {
        let allProgress = 0;
        models.forEach((model) => {
          allProgress += model.progress || 0;
        });
        const result = allProgress / models.length || 0;
        return Math.floor(result);
      },
      changeSection() {
        this.sectionActive = "statistics";
        this.calcStatistics("general");
      },
      async calcStatistics(filter) {
        const { data } = await this.$api.get(`${process.env.VUE_APP_API_IP}goals`);
        this.modelsGoals = data.modelsGoal;
        this.statisticData = {};

        if (filter === "all") {
          this.statisticMessage = "Meta por ciudad";
          const bogota = this.modelsGoals.filter((model) => model.city === "Bogotá");
          const cucuta = this.modelsGoals.filter((model) => model.city === "Cúcuta");
          const pereira = this.modelsGoals.filter((model) => model.city === "Pereira");
          const medellin = this.modelsGoals.filter((model) => model.city === "Medellín");

          this.statisticData = {
            Bogotá: await this.getAverage(bogota),
            Cúcuta: await this.getAverage(cucuta),
            Medellín: await this.getAverage(pereira),
            Pereira: await this.getAverage(medellin),
          };
        }

        if (filter === "general") {
          this.statisticMessage = "Meta general";
          this.statisticData = { General: await this.getAverage(this.modelsGoals) };
        }
      },
      cleanFilters() {
        const defaultSelect = document.querySelector('option[value="all"]');
        this.city = null;
        defaultSelect.selected = true;
        this.$refs.search.searchValue = "";
        this.page = 0;
        this.$store.dispatch("goals/getModelGoals", { reset: true });
      },
      createMessage({ name, value, type }) {
        const messages = {
          days: `<p><strong>Meta:&nbsp;</strong>${name}</p><p><strong>Tiempo: </strong>${this.$global.parseDaysToDate(value)}&nbsp;</p>`,
          dollars: `<p><strong>Meta:&nbsp;</strong>${name}</p><p><strong>Dolares: </strong>${this.$global.formatPrice(value)}&nbsp;</p>`,
          hours: `<p><strong>Meta:&nbsp;</strong>${name}</p><p><strong>Horas: </strong>${this.$global.parseMinutesToTime(value)}&nbsp;</p>`,
          quantity: `<p><strong>Meta:&nbsp;</strong>${name}</p><p><strong>Historias: </strong>${value}&nbsp;</p>`,
        };
        return messages[type];
      },
      parseGoalToMessage() {
        this.goals.forEach((goal) => {
          const { name, goal: value, type, subType } = goal;
          goal.message = this.createMessage({ name, value, type: subType ? subType : type });
        });
      },
      editVisible(inputRef) {
        const activeModal = document.querySelector(".editActive");
        if (activeModal) {
          if ([...activeModal.classList].includes(`c${inputRef}`)) {
            activeModal.classList.remove("editActive");
            return;
          }
          activeModal.classList.remove("editActive");
          const input = document.querySelector(`.c${inputRef}`);
          input.classList.add("editActive");
          return;
        }
        const input = document.querySelector(`.c${inputRef}`);
        input.classList.add("editActive");
      },
      async getMoreGoals() {
        this.isLoading = true;
        this.page++;
        await this.$store.dispatch("goals/getModelGoals", { page: this.page, city: this.city });
        this.isLoading = false;
      },
    },
    computed: {
      goals() {
        return this.$store.state.goals.goals;
      },
      modelsGoals() {
        return this.$store.state.goals.modelGoals;
      },
      hasMore() {
        return this.$store.state.goals.hasMore;
      },
    },
    watch: {
      goals() {
        this.parseGoalToMessage();
      },
    },
    async beforeMount() {
      this.$store.dispatch("goals/initGoals");
      this.$store.dispatch("goals/getModelGoals");
      this.cities = dictionary.dictionary.cities;
      this.parseGoalToMessage();
    },
    beforeDestroy() {
      this.$store.state.goals.modelGoals = [];
    },
  };
</script>
<style lang="scss">
  .goals {
    margin-top: 20px;
    width: 100%;
    max-width: 1440px;

    &__emptyCity {
      margin-top: 30px;
      text-align: center;
    }

    &__pagination {
      button {
        opacity: 1;
        margin: 0 auto;
        margin-top: 10px;
      }
    }

    &__fieldset {
      border: 2px solid $primary_color;
      padding: 0 20px;
      padding-bottom: 28px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
      legend {
        font-family: $sec_font;
        color: $primary_color;
        padding: 0 5px;
        width: auto;
        display: inline-block;
        font-size: 32px;
      }
    }

    .goalsNav {
      max-width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
      border-radius: 5px;
      font-family: $first_font;
      font-size: 14px;
      background: $white;
      box-shadow: 0 2px 5px $alto;
      @include Row;
      flex-wrap: wrap;
      gap: 10px;
      padding: 12px 8px;

      &__data {
        display: flex;
        gap: 10px;
      }

      &__search {
        width: 100%;
        max-width: 300px;
      }

      &__selectContainer {
        width: 56%;
        max-width: 180px;
      }

      &__select {
        width: 100%;
        height: 32px;
        border-color: $primary_color;
        padding: 0 2px;
        border-radius: 2px;
        &:not(:-internal-list-box) {
          background-color: none;
        }
      }

      &__editGoal {
        @include Row;
        margin-top: 10px;
        position: relative;
        .disabled {
          opacity: 50%;
        }
      }

      &__editBtn {
        @include ButtonRed;
        font-size: 14px;
        padding: 8px;
        border: 1px solid;
        border-radius: 3px;
        outline: none;
        @include Row();
        justify-content: space-between;
        min-width: 261px;
        p {
          text-align: left;
        }
      }

      &__removeFilters {
        @include Row;
        width: 40%;
        max-width: 110px;
        gap: 10px;
        color: $primary_color;
        border-radius: 3px;
        cursor: pointer;
      }

      &__selecSection {
        display: flex;
        gap: 20px;
        margin-top: 10px;
      }

      &__statistics {
        @include Row();
        cursor: pointer;
        background: $mako;
        color: white;
        padding: 8px 11px;
        border-radius: 3px;
        gap: 8px;
        i {
          color: white;
        }
      }

      .sectionActive {
        opacity: 50%;
      }
    }
  }

  .editActive {
    display: block;
  }

  @media (min-width: $tablet_width) {
    .goals {
      &__fieldset {
        justify-content: flex-start;
      }

      .goalsNav {
        gap: 7px;
        &__search {
          width: 160px;
        }

        &__editBtn {
          min-width: auto;
          gap: 10px;
          & > p {
            display: flex;
            gap: 5px;
          }
        }

        &__select {
          width: 160px;
        }

        &__selecSection {
          margin-top: 0;
          margin-left: auto;
        }

        &__editGoal {
          justify-content: flex-start;
        }
      }
    }
  }

  @media (min-width: $desktop_width) {
    .goals {
      .goalsNav {
        justify-content: flex-start;
        font-size: 14px;
        gap: 12px;
        &__search {
          width: 95px;
        }

        &__select {
          width: 170px;
        }
      }
    }
  }

  @media (min-width: $tv_width) {
    .goals {
      .goalsNav {
        gap: 20px;
        &__search {
          width: 160px;
        }

        &__select {
          width: 170px;
        }

        &__data {
          gap: 20px;
        }
      }
    }
  }
</style>
