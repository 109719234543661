<template>
  <div class="modelsGoalsCard generalCard" v-if="model.modelData.artisticName">
    <div class="modelsGoalsCard-generalInfo" :class="showProgressBars ? 'visibleGoals' : ''" @click="handleGoalBars">
      <div class="modelsGoalsCard-modelInfoContainer">
        <div class="modelsGoalsCard-personalInfo">
          <Avatar class="modelsGoalsCard-avatar" :user="getModel(model.modelData)" />
          <div class="modelsGoalsCard-info">
            <p>{{ model.modelData.artisticName }}</p>
            <strong>{{ model.modelData.city }}</strong>
            <div class="modelsGoalsCard-streamPlataforms">
              <div v-if="model.modelData.jasminUser" class="modelsGoalsCard-streamBadged jasmin">Jasmin</div>
              <div v-if="model.modelData.streamateUser" class="modelsGoalsCard-streamBadged streamate">Streamate</div>
            </div>
          </div>
        </div>
        <div class="modelsGoalsCard-starts">
          <span :class="['modelsGoalsCard-start', `m${model.modelData._id}`]" value="1">★</span>
          <span :class="['modelsGoalsCard-start', `m${model.modelData._id}`]" value="2">★</span>
          <span :class="['modelsGoalsCard-start', `m${model.modelData._id}`]" value="3">★</span>
          <span :class="['modelsGoalsCard-start', `m${model.modelData._id}`]" value="4">★</span>
          <span :class="['modelsGoalsCard-start', `m${model.modelData._id}`]" value="5">★</span>
        </div>
      </div>
      <div class="modelsGoalsCard-awards" :key="awardsKey">
        <Badge v-for="badge in modelBadges" :key="badge._id" :badge="badge" :modelId="model.modelData._id" />
        <Award v-for="award in model.awards" :key="award._id" :award="award" :modelId="`${model.modelData._id}award`" />
      </div>
    </div>
    <div class="modelsGoalsCard-progressBars" v-if="showProgressBars">
      <div class="modelsGoalsCard-addBadgeBtn">
        <button class="generalBtn" @click="badgeModal = !badgeModal">
          Badge
          <iconic name="plus_circle" />
        </button>
        <BadgeForm v-if="badgeModal" @closeBadgeModal="badgeModal = false" :modelId="model.modelData._id" />
      </div>
      <Bar class="goalsBarCard" v-for="goal in model.goals" :key="goal._id" :goal="goal" :modelGoals="model" :modelData="model.modelData" />
    </div>
  </div>
</template>
<script>
import Bar from "./Bar.vue";
import InputDates from "./InputEdit.vue";
import Award from "./awards/Award.vue";
import BadgeForm from "./inputTypes/Badge.vue";
import Badge from "./Badge.vue";

export default {
  components: { Bar, InputDates, Award, BadgeForm, Badge },
  props: ["model"],
  data() {
    return {
      showProgressBars: false,
      badgeModal: false,
      awardsKey: 0,
    };
  },
  methods: {
    getModel(model) {
      return { role: "model", artisticName: model.artisticName, picture: model.picture, user: model.artisticName };
    },

    setQualification() {
      let starts = document.querySelectorAll(`.m${this.model.modelData._id}`);
      starts.forEach((start) => {
        if (start.getAttribute("value") <= this.model.modelData.qualification) {
          start.classList.add("starActive");
        }
      });
    },
    handleGoalBars({ path }) {
      const openBars = !path?.some((el) => el.classList?.contains("badgeItem-btns"));
      if (openBars) this.showProgressBars = !this.showProgressBars;
    },
  },
  computed: {
    modelBadges() {
      return this.model.badges.reverse();
    },
  },
  watch: {
    "model.awards"() {
      this.awardsKey++;
    },
  },
  mounted() {
    this.setQualification();
  },
};
</script>
<style lang="scss">
.modelsGoalsCard {
  margin-top: 10px;
  border-radius: 5px;
  &-addBadgeBtn {
    margin-left: 10px;
    position: relative;
    @media (min-width: $tablet_width) {
      margin-left: 30px;
    }
    i {
      margin-left: 3px;
      font-size: 15px;
    }
    .inputEdit__container {
      margin-left: 0;
      top: 45px;
    }
  }

  &-starts {
    font-size: 40px;
    color: #ccc;
    margin-left: 20px;
  }

  &-generalInfo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    padding: 15px 0;
  }

  &-progressBars {
    padding-top: 20px;
  }

  &-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-left: 20px;
    flex-shrink: 0;
  }

  &-awards {
    margin-left: 17px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    svg {
      width: 120px;
      height: 120px;
      text {
        font-family: $sec_font;
      }
    }
  }

  &-personalInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  &-streamPlataforms {
    display: flex;
    gap: 6px;
    @media (min-width: $tablet_width) {
      gap: 8px;
    }
  }

  &-streamBadged {
    text-align: center;
    width: auto;
    border-radius: 4px;
    color: white;
    font-size: 10px;
    padding: 3px 5px;
    @media (min-width: $tablet_width) {
      padding: 4px 8px;
    }
  }

  .jasmin {
    background-color: $lipstick;
  }

  .streamate {
    background-color: $matisse;
  }

  &-name {
    margin-left: 30px;
    color: $primary_color;
  }

  &-info {
    @media (min-width: $mobile_small_width) {
      min-width: 150px;
    }

    p {
      line-height: 13px;
    }

    strong {
      font-family: $sec_font;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .starActive {
    color: $lipstick;
  }

  .visibleGoals {
    background: $backgroundCards;
  }
}

@media (min-width: $tv_width) {
  .modelsGoalsCard {
    &-name {
      width: 170px;
    }

    &-generalInfo {
      flex-direction: row;
      align-items: center;
    }

    &-personalInfo {
      width: 450px;
    }

    &-awards {
      margin-right: 20px;
    }
  }
}
</style>
