<template>
  <div class="modelsGoalsCard generalCard">
    <div class="modelsGoalsCard-generalInfo" :class="showProgressBars ? 'visibleGoals' : ''" @click="showProgressBars = !showProgressBars">
      <div class="modelsGoalsCard-modelInfoContainer">
        <div class="modelsGoalsCard-personalInfo">
          <Avatar class="modelsGoalsCard-avatar" :user="model" />
          <div class="modelsGoalsCard-info">
            <p>{{ model.artisticName }}</p>
            <strong>{{ model.city }}</strong>
            <div class="modelsGoalsCard-streamPlataforms">
              <div v-if="model.jasminUser" class="modelsGoalsCard-streamBadged jasmin">Jasmin</div>
              <div v-if="model.streamateUser" class="modelsGoalsCard-streamBadged streamate">Streamate</div>
            </div>
          </div>
        </div>
        <button class="generalBtn">Modificar metas</button>
      </div>
    </div>
    <div class="goalsAdmin__goalItems" v-if="showProgressBars">
      <ul class="goalsAdmin__goalsList" v-if="modelGoals.modelGoals.length">
        <MonitorControlls v-for="goal in modelGoals.modelGoals" :key="goal._id" :goal="goal" :model="model._id" :modelData="modelGoals" />
      </ul>
    </div>
  </div>
</template>
<script>
import MonitorControlls from "./MonitorControlls.vue";

export default {
  components: { MonitorControlls },
  props: ["model"],
  data() {
    return {
      showProgressBars: false,
    };
  },
  computed: {
    goals() {
      const goals = this.$store.getters.getGoals.filter((goal) => goal.type !== "days");
      return goals;
    },
    modelGoals() {
      if (this.$store.state.goals.modelsGoalsFiltered[0]) {
        return this.$store.state.goals.modelsGoalsFiltered.find((model) => model.modelId._id === this.model._id);
      }
      return { modelGoals: [] };
    },
  },
  async beforeMount() {
    await this.$store.dispatch("initModelsGoals", { type: "model", value: this.model._id });
  },
};
</script>
<style lang="scss">
.goalItem {
  border-top: 1px solid $silver;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  &:last-child {
    border-bottom: 1px solid $silver;
  }
  &__title {
    width: 170px;
  }

  &__controlls {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  input {
    padding: 6px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;
    background-color: white;
  }

  &__dateInput {
    width: 200px;
  }

  &__valueInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
      width: 200px;
    }
  }

  &__timeToAddBar {
    width: 100%;
    height: 8px;
    background: $primary_color;
    border-radius: 5px;
  }

  &__message {
    font-size: 14px;
    font-weight: bold;
  }

  &__messageContainer {
    min-width: 150px;
  }

  button {
    width: 78px;
    border-radius: 6px;
    background-color: $lipstick;
    outline: none;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  @media (min-width: $tablet_width) {
    &__valueInput {
      margin-left: 10px;
      flex-direction: row;
      align-items: center;
    }

    &__controlls {
      flex-direction: row;
      align-items: center;
    }

    button {
      margin-left: auto;
    }
  }
}
</style>
