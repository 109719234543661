<template>
  <li :class="`goalItem goal${goal._id}`" v-show="goal.goalData.type !== 'days' && goal.goalData.type !== 'topmodel' && goal.goalData.enable">
    <p class="goalItem__title">{{ goal.goalData.goalName }}</p>
    <div class="goalItem__controlls">
      <input class="goalItem__dateInput" type="date" v-model="date" />
      <div class="goalItem__valueInput">
        <input type="text" v-model="value" placeholder="1h 30min" v-if="goal.goalData.type === 'hours'" />
        <input type="number" v-model="value" placeholder="Valor" v-else />
        <div class="goalItem__messageContainer">
          <div v-show="message" class="goalItem__message">
            <p>{{ message }}</p>
            <div class="goalItem__timeToAddBar"></div>
          </div>
        </div>
      </div>
      <button class="generalBtn" :disabled="!message || !date || valueToSend <= 0" @click="sendUpdate">Enviar</button>
    </div>
    <Bar :goalData="goal" :modelInfo="{ _id: model }" :isMonitor="true" :modelData="modelData" />
  </li>
</template>
<script>
import alerts from "../../js/swalAlerts";
import Bar from "./Bar.vue";
import { socket } from "@/socket";

export default {
  props: ["goal", "model", "modelData"],
  components: { Bar },
  data() {
    return {
      date: null,
      value: null,
      valueToSend: null,
      message: "",
    };
  },
  methods: {
    async sendUpdate() {
      alerts
        .dataSendConfirm({
          message: `El progreso de la modelo será actualizado`,
        })
        .then(async ({ isConfirmed }) => {
          if (!isConfirmed) return;
          const response = await this.updateModelGoal();
          this.value = "";
          if (response) {
            alerts.dataSendSuccess({ message: "Meta de la modelo actualizada" });
          } else {
            this.badValue
              ? alerts.dataSendError({ message: "El valor no puede ser inferior o igual al actual" })
              : alerts.dataSendSuccess({ message: "Fallo al actualizar la meta de la modelo" });
          }
        });
    },
    async updateModelGoal() {
      try {
        const currentProgress = this.goal.increase - this.goal.decrease;
        if (currentProgress >= +this.valueToSend) {
          this.badValue = true;
          return false;
        }
        const response = await this.$store.dispatch("editModelGoal", {
          goalId: this.goal.goalData._id,
          action: "increase",
          value: +this.valueToSend - currentProgress,
          modelId: this.model,
          type: this.goal.goalData.type,
        });
        const currentProgressUpdated = this.goal.increase - this.goal.decrease;
        let progressMesagge;
        if (this.goal.goalData.type === "hours") {
          progressMesagge = this.$global.parseMinutesToTime(currentProgressUpdated);
        }
        if (this.goal.goalData.type === "dollars") {
          progressMesagge = this.$global.formatPrice(currentProgressUpdated);
        }
        if (this.goal.goalData.type === "quantity") {
          progressMesagge = `${currentProgressUpdated} Historias`;
        }
        socket.emit("modelGoalUpdated", {
          modelId: this.model,
          date: this.date,
          monitor: { user: this.$store.state.user.profile.user, picture: this.$store.state.user.profile.picture },
          value: progressMesagge,
          goal: this.goal.goalData.goalName,
          role: "monitor",
        });

        return response;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    parseDataToTime(value) {
      const match = /(\d+h{1}|\d+min{1})?\s?(\d+min{1})?/.exec(value);
      if (match[0]) {
        const coincidences = match[0]?.split(" ");

        let mins = 0;
        coincidences.forEach((coincidence) => {
          if (coincidence) {
            const text = /(h|min)/.exec(coincidence)[0];
            const num = /\d+/.exec(coincidence)[0];
            mins += this.getTime(text, +num);
          }
        });
        return { message: this.$global.parseMinutesToTime(mins), mins };
      }
      return { message: "", days: 0 };
    },
    getTime(text, num) {
      if (text === "h") {
        return num * 60;
      }
      if (text === "min") {
        return num;
      }
    },
  },
  watch: {
    value() {
      if (this.goal.goalData.type === "hours") {
        const { message, mins } = this.parseDataToTime(this.value.toLowerCase());
        this.message = message;
        this.valueToSend = mins;
      }

      if (this.goal.goalData.type === "dollars") {
        this.message = this.value > 0 ? `${this.$global.formatPrice(this.value)}` : "";
        this.valueToSend = this.value;
      }
    },
  },
  beforeMount() {
    this.date = this.$global.getDateYMD();
  },
};
</script>
<style></style>
