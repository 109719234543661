<template>
  <div class="searchUsers">
    <div class="searchUsers__overlay" @click="leaveSearch"></div>
    <div class="searchUsers__form">
      <div class="iconInput">
        <i class="fas fa-search iconInput__i--search"></i>
        <input
          class="iconInput__input"
          type="search"
          name="search"
          v-model="searchValue"
          :placeholder="placeholder"
          @keyup="autoSearch"
          @mouseup="enterSearch"
          autocomplete="off"
        />
      </div>
      <div class="searchUsers__results">
        <div class="searchUsers__container">
          <div class="searchUsers__item" @click="viewModel(model), leaveSearch()" v-for="(model, midx) in search" :key="midx">
            <div>{{ model.artisticName }}</div>
          </div>
          <div v-if="isLoading" class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["placeholder"],
  data() {
    return {
      search: [],
      searchValue: "",
      page: 1,
      isLoading: false,
      hasMore: true,
    };
  },
  methods: {
    enterSearch: function() {
      if (!this.search?.length) {
        this.getModels();
      }
      const mysearch = document.querySelector(".searchUsers__overlay");
      const results = document.querySelector(".searchUsers__container");
      mysearch.classList.add("overlay");
      results.style.display = "block";
    },
    leaveSearch: function() {
      const mysearch = document.querySelector(".searchUsers__overlay");
      const results = document.querySelector(".searchUsers__container");
      mysearch.classList.remove("overlay");
      results.style.display = "none";
      this.search = [];
      this.searchValue = "";
      this.page = 1;
      this.hasMore = true;
    },
    async getModels() {
      this.isLoading = true;
      const { data } = await this.$axios.get(`/users/search?role=model&search=${this.searchValue}&limit=8&page=${this.page - 1}`);
      this.search = [...this.search, ...data.models];
      this.hasMore = data.hasMore;
      this.isLoading = false;
    },
    async autoSearch() {
      this.isLoading = true;
      const { data } = await this.$axios.get(`/users/search?role=model&search=${this.searchValue}&limit=8&page=0`);
      this.search = data.models;
      this.hasMore = data.hasMore;
      this.isLoading = false;
    },
    viewModel(model) {
      this.$emit("viewModel", model);
    },
    isScrolling({ srcElement }) {
      const { scrollTop, offsetHeight, scrollHeight } = srcElement;
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight;
      if (scrolledToBottom) {
        if (this.hasMore) {
          this.page++;
          this.getModels();
        }
      }
    },
  },
  mounted() {
    const userList = document.querySelector(".searchUsers__results");
    if (userList) {
      userList.removeEventListener("scroll", this.isScrolling);
      userList.addEventListener("scroll", this.isScrolling);
    }
  },
};
</script>

<style lang="scss">
.searchUsers {
  .iconInput {
    position: relative;
    z-index: 10;
    &__i--search {
      cursor: pointer;
      top: 8px;
      left: 10px;
      position: absolute;
    }
    &__input {
      cursor: pointer;
      width: 100%;
      padding-left: 29px;
      border-radius: 2px;
      border: 1px solid $lipstick;
      height: 32px;
      outline: none;
    }
  }
  &__item {
    padding: 10px;
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
    color: $mine_shaft;
    border-bottom: 1px solid $porcelain;
    &:hover {
      background: $porcelain;
    }
  }
  &__role {
    font-size: 12px;
    color: $lipstick;
  }

  &__results {
    width: auto;
    min-width: 250px;
    max-height: 270px;
    position: absolute;
    z-index: 52;
    overflow-x: auto;
    border-radius: 5px;
    background: $white;

    .spinner {
      margin-bottom: 10px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.144);
  z-index: 1;
}

@media (min-width: $tablet_width) {
  .searchUsers {
    .iconInput {
      position: relative;
      &__i {
        left: 3px;
      }
    }
  }
}
@media (min-width: $tv_width) {
  &__i--search {
    top: 20px;
  }
}
</style>
